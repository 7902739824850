<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-5 mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              $t("modals.hotel.staffList.title")
            }}</span>
            <span class="text-muted fs-7">{{
              $t("modals.hotel.staffList.desc")
            }}</span>
          </h3>
          <!--begin::Actions-->
          <div class="justify-content-end">
            <router-link to="/invite-staff">
              <button type="submit" class="btn btn-sm btn-primary">
                {{ $t("ogza.staffList.inviteStaff.title") }}
              </button>
            </router-link>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-5">
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div
              v-if="isLoading"
              style="
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="text-center px-5 pb-7">
                <img
                  alt="Logo"
                  class="max-h-25px"
                  src="/media/loading_icon.gif"
                  style="width: 35px"
                />
              </div>
            </div>
            <div class="table-responsive" v-else-if="staffList.length > 0">
              <table
                class="table align-middle table-row-dashed fs-6 gy-5 no-footer"
                id="kt_customers_table"
                role="grid"
              >
                <!--begin::Table-->
                <thead>
                  <tr
                    class="
                      text-start text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                    role="row"
                  >
                    <td width="15%">
                      {{ $t("modals.hotel.staffList.name") }}
                      {{ $t("modals.hotel.staffList.surname") }}
                    </td>
                    <td width="15%">
                      {{ $t("modals.hotel.staffList.email") }}
                    </td>
                    <td width="15%">
                      {{ $t("modals.hotel.staffList.department") }}
                    </td>
                    <td width="20%">
                      {{ $t("modals.hotel.staffList.mission") }}
                    </td>
                    <!--
                    <td width="10%">
                      {{ $t("modals.hotel.staffList.sessionCount") }}
                    </td>-->
                    <td width="20%">
                      {{ $t("modals.hotel.staffList.phone") }}
                    </td>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-600 text-center">
                  <tr
                    class="odd clickable-row"
                    v-for="(staff, index) in staffList"
                    :key="index"
                    @click="goToStaffDetail(staff.staff.id)"
                  >
                    <td>{{ staff.staff.name }} {{ staff.staff.surname }}</td>
                    <td>{{ staff.staff.email }}</td>
                    <td>{{ staff.staff.department }}</td>
                    <td>{{ staff.staff.position }}</td>
                    <!--<td>10</td>-->
                    <td>
                      +{{ staff.staff.countryCodeNumber }}
                      {{ staff.staff.phoneNumber }}
                    </td>
                  </tr>
                </tbody>
                <!--end::Table-->
              </table>
            </div>
            <div v-else class="card-px text-center">
              <div class="text-center px-5 pb-7"></div>
              <!--begin::Description-->
              <p class="text-gray-700 fs-6 fw-bold">
                <span>{{ $t("modals.hotel.staffList.zeroCount") }}</span>
              </p>
              <!--end::Description-->
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { StaffListModel } from "@/domain/staff/staff-list/model/StaffListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

export default defineComponent({
  name: "staff-list",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  methods: {
    goToStaffDetail(staffId) {
      this.$router.push(`/hotel-management/staff-list/${staffId}`);
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const loading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const staffController = store.state.ControllersModule.staffController;
    const staffList = ref<StaffListModel[]>([]);
    const isLoading = ref<boolean>(false);

    const getStaffList = async () => {
      isLoading.value = true;

      const staffListModel: StaffListModel = {
        staff: {},
        page: 1,
        pageSize: 50,
      };

      staffController
        .staffList(staffListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((staff) => {
              if(staff.staff.role != "TENANT_ADMIN")
              staffList.value.push(staff);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.hotel.title"));
      getStaffList();
    });

    return {
      loading,
      staffList,
      isLoading,
    };
  },
});
</script>

<style scoped>
.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #f8f6f6; /* Gri arka plan rengi */
}
</style>
